<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input style="width:200px;" v-model="filters.keyword" @input="(e) => (filters.keyword = utils.validForbid(e))" placeholder="关键字" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="所属上级:">
                        <el-cascader :key="isResouceShow" placeholder="请选择所属上级" @change="changeSearchParent"
                            :options="options" :props="{ checkStrictly: true, value: 'id', label: 'name' }"
                            clearable></el-cascader>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>

        </el-row>
        <!-- 列表 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
            @current-change='currentChange' :highlight-current-row='true' :cell-style="rowClass">
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="name" label="名称" align="center" width="" show-overflow-tooltip></el-table-column>
            <el-table-column prop="parentId" label="所属上级" align="center" width="">
                <template slot-scope="scope">
                    {{ scope.row.parentId === 0 ? '无上级' : scope.row.parentNames}}
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>

        <!-- 新建弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="addDialogShow" v-model="addDialogShow"
            :close-on-click-modal="false" width='50%'>
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px" style='min-width="800px"'>
                <el-form-item label="名称:" prop="name">
                    <el-input v-model="addForm.name"></el-input>
                </el-form-item>
                <el-form-item label="所属上级:" prop="parentId">
                    <el-cascader :key="isResouceShow" v-model="parentIdArr" placeholder="请选择所属上级" @change="changeParent"
                        :options="options" :props="{ checkStrictly: true, value: 'id', label: 'name' }"
                        clearable></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addDialogClose">取消</el-button>
                <el-button type="primary" @click="addSubmit" :loading="addLoading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
    getAuditContentListPage,
    getAuditContentAllList,
    getAuditContentTreeList,
    getAuditContentById,
    addAuditContent,
    updateAuditContent,
    deleteAuditContent
} from '@/api/api'

export default {
    components: { Toolbar },
    data() {
        return {
            utils: '',
            buttonList: [],
            isResouceShow: false,
            needData: '',
            filters: {
                keyword: '',
                parentId: undefined
            },
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 新建
            addDialogShow: false,
            addLoading: false,
            dialogTitle: '',
            addForm: {
                id: undefined,
                name: '',
                parentId: undefined,
            },
            addRules: {
                name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
                parentId: [{ required: true, message: '请选择所属上级', trigger: 'change' }],
            },
            options: [],
            parentIdArr: []
        }
    },
    watch: {

    },
    created() {
        this.utils = util
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
        this.getOptions();
    },
    methods: {
      getListData(){
        this.pages.pageIndex=1;
        this.getDataList()
      },
        callFunction(item) {
            if(item.func == "getDataList") {
                this.pages.pageIndex = 1
            }
            this[item.func].apply(this, item);
        },
        getOptions() {
            this.isResouceShow = false;
            getAuditContentTreeList().then(res => {
                if (res.data.success) {
                    this.options = [
                        {
                            parentId: -1,
                            id: 0,
                            name: "无上级",
                            children: res.data.response
                        }
                    ];
                    this.isResouceShow = true;
                }
            })
        },
        // 选择查询所属上级
        changeSearchParent(data) {
            this.filters.parentId = data[data.length - 1];
        },
        // 选择新增修改所属上级
        changeParent(data) {
            this.addForm.parentId = data[data.length - 1];
        },
        // 获取所属上级
        getParentArr(parentId) {
            let res = this.options.filter(item => {
                return item.id === parentId
            })
            this.parentIdArr = [res[0].id]
        },
        // 列表查询接口
        getDataList() {
            let params = {
                keyword: this.filters.keyword,
                parentId: this.filters.parentId,
                pageSize: this.pages.pageSize,
                pageIndex: this.pages.pageIndex
            }
            this.listLoading = true
            getAuditContentListPage(params).then(res => {
                if (res.data.success) {
                    this.tableData = res.data.response.data;
                    this.pages.dataCount = res.data.response.dataCount;
                }
                this.listLoading = false
            })
        },
        // 新建
        handleAdd() {
            this.addDialogShow = true;
            this.addLoading = false;
            this.dialogTitle = '新增';
            this.parentIdArr = [];
            this.addForm = {
                id: undefined,
                name: '',
                parentId: undefined,
            }
        },
        addDialogClose() {
            this.addDialogShow = false;
            this.addForm = {
                id: undefined,
                name: '',
                parentId: undefined,
            }
        },
        handleEdit() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            getAuditContentById({ id: this.needData.id }).then(res => {
                if (res.data.success) {
                    const { response: data } = res.data;
                    this.addDialogShow = true;
                    this.addLoading = false;
                    this.dialogTitle = '编辑';
                    this.addForm = {
                        id: this.needData.id,
                        name: data.name,
                        parentId: data.parentId,
                    }
                    this.getParentArr(data.parentId);
                }
            })
        },
        // 新建提交
        addSubmit() {
            this.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    this.addLoading = true
                    if (this.addForm.id === undefined) {
                        let params = {
                            ...this.addForm
                        }
                        if (JSON.parse(localStorage.getItem("user")).id > 0) {
                            params.createUserId = JSON.parse(localStorage.getItem("user")).id
                        } else {
                            this.$message({
                                message: "用户信息为空，先登录",
                                type: "error"
                            });
                            _this.$router.replace(
                                "/"
                            );
                            return
                        }
                        addAuditContent(params).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.getOptions();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                            }
                            this.addLoading = false
                        })
                    } else {
                        updateAuditContent(this.addForm).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.getOptions();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                            }
                            this.addLoading = false
                        })
                    }

                }
            })
        },
        handleDel() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            this.$confirm('确认删除?', '提示', {
                type: 'warning'
            }).then(() => {
                deleteAuditContent({ id: this.needData.id }).then(res => {
                    if (res.data.success) {
                        this.$message({
                            message: res.data.message,
                            type: "success"
                        });
                        this.getDataList();
                    } else {
                        this.$message({
                            message: res.data.message,
                            type: "error"
                        });
                    }
                })
            })
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function (row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
                ? ""
                : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        rowClass({columnIndex}){
            return 'padding:8px!important;'
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
    }
}
</script>

<style lang="stylus" scoped>

</style>
